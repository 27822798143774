import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { StaticImage } from "gatsby-plugin-image";
import Typography from "../components/Typography/Typography";
import MainLayout from "../components/mainlayout";
import { Link } from "gatsby";
import {
  mobileViewBreakpoint,
  extraLargeDesktopViewBreakpoint
} from "../components/helper";
import "../assets/css/main.css";

const useStyles = makeStyles(theme => ({
  caseStudyContainer: {
    boxSizing: "border-box",
    padding: "0px 32px",
    maxWidth: "1234px",
    margin: "80px auto 80px auto",
    "& > h2": {
      marginBottom: "28px",
      color: "#122044",
      [theme.breakpoints.down(mobileViewBreakpoint)]: {
        marginBottom: "24px",
        color: "#122044"
      }
    },
    "& > h5": {
      [theme.breakpoints.down(mobileViewBreakpoint)]: {
        marginBottom: "24px"
      }
    },

    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      padding: "0px 0px 40px 0px",
      margin: "80px auto 0px auto"
    }
  }
}));
const Article = () => {
  const classes = useStyles();
  const theme = useTheme();

  const data = useStaticQuery(graphql`
    query {
      bannerImage: file(
        relativePath: { eq: "events/Event_Transportation.jpg" }
      ) {
        childImageSharp {
          fixed(width: 800, height: 800) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return (
    <MainLayout
      title={"EXPO 2020 VIP Transport SERVICES"}
      h1
      banner={
        <StaticImage
          src="../assets/images/events/Event_Transportation.jpg"
          draggable="false"
        />
      }
      metaTitle={"Expo 2020 Dubai VIP Transport Services"}
      metaDescription={
        "Discover our Expo transport program and VIP transport services. As we strive to provide an exceptional guest experience and reliable transport solutions."
      }
      shareImage={data.bannerImage.childImageSharp.fixed.src}
      breadcrumbs={[{ label: "Expo Transport Services" }]}
    >
      <div className={classes.root}>
        <div className={classes.caseStudyContainer}>
          <p>
            <Typography component="span" customVariant={"h5Regular"}>
              If you're an EXPO 2020 Dubai participant or visitor, you may be
              aware of the value of
              <a href="/event-transportation-service/">event transportation</a>.
              Having an economical transportation system is crucial to
              preserving the efficient ground operations of your event. Your
              transport systems are the first and last experience your guests or
              clients will remember. MOTUS | ONE's EXPO Transport Programs lets
              you focus on other tasks and aspects of your event - making your
              EXPO 2020 time more productive.
            </Typography>
          </p>

          <p>
            <Typography component="span" customVariant={"h5Regular"}>
              Part of your EXPO 2020 Dubai planning ensures that your most
              essential clients/guests are taken care of. MOTUS | ONE will
              provide the best experience possible for your VIP guests, whether
              before, during, or after EXPO 2020 with our VIP transport
              services. Knowing that this phase of your transportation is looked
              after, you can focus on other tasks to make your EXPO 2020 Dubai
              experience more memorable.
            </Typography>
          </p>

          <p>
            <Typography component="span" customVariant={"h5Regular"}>
              MOTUS | ONE provides the best solutions to deliver an exceptional
              guest experience and is what our clients look for when vetting VIP
              transportation services. Transport solutions should be responsive,
              reliable, and customer-oriented.
            </Typography>
          </p>

          <p>
            <Typography component="span" customVariant={"h5Regular"}>
              MOTUS | ONE provides professionally-trained Drivers and VIP
              services. We engage only the most qualified and well-trained
              chauffeurs that are professional and courteous. Our drivers are on
              the frontline of customer care and have a comprehensive
              understanding of security, safety, and privacy.
            </Typography>
          </p>

          <p>
            <Typography component="span" customVariant={"h5Regular"}>
              Consistency is vital in delivering the best service. We utilize
              technology and efficient client communication. MOTUS | ONE offers
              the best client service that's consistent and measurable.
            </Typography>
          </p>

          <p>
            <Typography component="span" customVariant={"h5Regular"}>
              MOTUS | ONE provides the EXPO 2020 Dubai Transport department with
              professional experts to support the final planning and delivery of
              EXPO 2020's complex transport solutions. EXPO 2020 will welcome 25
              million visitors.
            </Typography>
          </p>

          <p>
            <Typography component="span" customVariant={"h5Regular"}>
              With a keen insight into the EXPO 2020 Dubai site, we are happy to
              provide our international clients and foreign delegations with
              tailored ground transportation services for EXPO 2020 Dubai.
            </Typography>
          </p>

          <p>
            <Typography component="span" customVariant={"h5Regular"}>
              <ul>
                <li>VIP Transport</li>
                <li>Airport Transfers & airside access</li>

                <ul style={{ paddingLeft: "24px !important" }}>
                  <li>
                    Pickups and meet and greet are designed according to the
                    requirements of our clientele
                  </li>
                  <li>Dubai International Airport</li>
                  <li>Al Maktoum International Airport</li>
                </ul>

                <li>Production Transport</li>
                <li>Talent Transport</li>
                <li>Staff and Workforce Transport</li>
                <li>Foreign Delegation Transport Systems</li>
                <li>Ground Transportation Management</li>
              </ul>
            </Typography>
          </p>
        </div>
      </div>
    </MainLayout>
  );
};

export default Article;
